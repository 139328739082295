<template>
                <div class="profile__time-wrp"  v-click-outside="onClickOutside">
                    <input class="profile__time-input" v-model="timezone" placeholder="Search time zone">
                    <div class="profile__time--choice">
                        <input class="modal__input" name="timeZone" type="radio" id="01" value="(UTC +03:00)" v-model="checkedTime">
                        <label class="modal__label" for="01">(UTC +03:00)</label>
                        <input class="modal__input" name="timeZone" type="radio" id="02" value="(UTC +04:00)" v-model="checkedTime">
                        <label class="modal__label" for="02">(UTC +04:00)</label>
                        <input class="modal__input" name="timeZone" type="radio" id="03" value="(UTC +05:00)" v-model="checkedTime">
                        <label class="modal__label" for="03">(UTC +05:00)</label>
                        <input class="modal__input" name="timeZone" type="radio" id="04" value="(UTC +06:00)" v-model="checkedTime">
                        <label class="modal__label" for="04">(UTC +06:00)</label>
                        <input class="modal__input" name="timeZone" type="radio" id="05" value="(UTC +07:00)" v-model="checkedTime">
                        <label class="modal__label" for="05">(UTC +07:00)</label>
                        <input class="modal__input" name="timeZone" type="radio" id="06" value="(UTC +08:00)" v-model="checkedTime">
                        <label class="modal__label" for="06">(UTC +08:00)</label>
                    </div>
                </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'


    export default {
        data() {
            return {
                checkedNames: [],
            }
        },
        directives: {
        clickOutside: vClickOutside.directive
        },
        methods: {
            onClickOutside () {
                this.$emit('close')
            }
        }
    }
</script>

<style scoped>
    input{
        outline: none;
    }
    
    .profile__time-wrp{
        width: 230px;
        height: 230px;
        background-color: #fff;
        position: absolute;
        padding: 15px;
        color: #222222;
        box-sizing: border-box;
        z-index: 19;
        left: 0;
        top: 42px;
        border: 1px solid #F6F7F9;
        position: absolute;
    }

    .profile__time--choice{
        display: flex;
        flex-direction: column;
        border-top: 2px solid #F5F6F8;
        max-height: 150px;
        overflow-y: auto;
    }

    .profile__time-input{
        width: 100%;
        margin: 0;
        padding: 4px 15px;
        height: 36px;
        border-radius: 30px;
        border: 2px solid #EAEEF1;
        margin-bottom: 8px;
        box-sizing: border-box;
        overflow-y: auto;
        font-family: Montserrat;
    }

    .modal__input{
        display: none;
    }

    .modal__label{
        position: relative;
        margin: 5px 0;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
    }
</style>