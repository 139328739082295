<template>
        <div class="change-pas-modal" v-click-outside="onClickOutside">
            <h3 class="change-pas-modal--title">
                Change password
            </h3>
            <form class="change-pas-modal__form">
                <button href="" class="close_X" @click="$emit('close')">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.33301 14.6667L7.99967 7.99999M14.6663 1.33333L7.99967 7.99999M7.99967 7.99999L14.6663 14.6667M7.99967 7.99999L1.33301 1.33333" stroke="#E7E7E8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <div class="change-pas-modal__item">
                    <p class="change-pas-modal__name">
                        <label for="">
                            Old password
                        </label>
                        <input type="password" v-model="currentPassword">
                    </p>
                    <p class="change-pas-modal__name">
                        <label for="">
                            New password
                        </label>
                        <input type="password" v-model="newPassword">
                    </p>
                    <p class="change-pas-modal__name">
                        <label for="">
                            Retype new password
                        </label>
                        <input type="password" v-model="confirmNewPassword">
                    </p>
                </div>
                <div class="buttons">
                    <button @click="changePassword" class="change-pas-modal__create">
                        Save
                    </button>
                    <button @click="$emit('close')">
                        Cancel
                    </button>
                </div>
            </form>
            <UserActionNotificationModal :message="notificationMessage" v-if="showUserNotificationModal" @close="showUserNotificationModal = false" />
        </div>
</template>

<script>
    import vClickOutside from 'v-click-outside'
    import UserActionNotificationModal from '@/components/Modal/UserActionNotificationModal'
    import { useProfileStore } from '@/store/ProfileStore'
    
    export default {
        data(){
            const profileStore = useProfileStore()
            return {
                store: profileStore,
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: '',
                showUserNotificationModal: false,
                notificationMessage: ''
            }
        },
        directives: {
            clickOutside: vClickOutside.directive
        },
        components: {
            UserActionNotificationModal
        },
        methods: {
            async changePassword(){
                let response = await this.store.updatePassword({ 
                    p: this.currentPassword, 
                    np: this.newPassword, 
                    npc: this.confirmNewPassword
                })
                if ( response.data.result == 'ok' ){
                    this.showUserNotificationModalMethod('Your password was updated')
                    setTimeout(this.closeModal, 2000)
                } else {
                    this.showUserNotificationModalMethod(response.data.message)
                }
            },
            showUserNotificationModalMethod(message){
                this.notificationMessage = message
                this.showUserNotificationModal = true
                setTimeout(this.closeUserNotificationModal, 2000)
            },
            closeUserNotificationModal(){
                this.showUserNotificationModal = false
            },
            closeModal(){
                this.$emit('close')
            },
            onClickOutside () {
                this.$emit('close')
            }
        }
    };
</script>

<style scoped>
    .change-pas-modal{
        width: 549px;
        height: auto;
        background-color: #fff;
        position: fixed;
        top: 20%;
        padding: 20px;
        color: #222222;
        box-sizing: border-box;
        margin-bottom: 50px;
        z-index: 20;
        right: calc( 50vw - 269px);
        margin-left: -273px;
    }

    .change-pas-modal--title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        text-align: left;
        margin: 0;
        padding-bottom: 25px;
        border-bottom: 2px solid #F2F3F6;
    }


    .change-pas-modal__name{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        text-align: left;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .change-pas-modal__item{
        display: flex;
        flex-direction: column;
    }

    .change-pas-modal__name input{
        margin-top: 5px;
        border-radius: 30px;
        height: 42px;
        border: 2px solid #F2F3F6;
        font-family: Montserrat;
        outline: none;
        padding: 0 15px;
        width: 100%;
        box-sizing: border-box;
    }

    button{
        border: none;
        background: none;
        cursor: pointer;
    }

    .buttons{
        display: flex;
        flex-direction: row-reverse;
    }

    .buttons button{
        margin-left: 10px;
        margin-top: 10px;
        width: 107px;
        height: 42px;
        font-family: 'Montserrat', sans-serif;
        background: #9CEDFF1A;
        border: 2px solid #9CEDFF;
        height: 42px;
        border-radius:30px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .buttons .change-pas-modal__create{
        background-color: #9CEDFF;
    }

    @media(max-width:800px){
        .bg{
            left: 0;
        }
    }

    
    @media(max-width:600px){
        .change-pas-modal{
            width: 100vw;
            margin-left: -75px;
            right: 0;
            box-sizing: border-box;
        }
    }

    .close_X{
        position: absolute;
        right: 19px;
        top: 23px;
    }

</style>