<template>
  <section>
    <h3 class="profile__title">Profile settings</h3>
    <ProfileWrp />
  </section>
</template>

<script>
    import ProfileWrp from '@/components/Profile/ProfileWrp'


    export default {
        components: {
          ProfileWrp
        }
    }
</script>

<style scoped>
      section{
        display: flex;
        flex-direction: column;
        padding: 20px 30px;
    }

    .profile__title{
        font-size: 26px;
        font-weight: 600;
        line-height: 32px;
        margin: 0;
    }
</style>