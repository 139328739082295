<template>
    <div class="profile__wrp">
        <div v-if="showLogo" class="profile__item name">
            <h3 class="profile__title">Company logo</h3>
            <p class="profile__info">Formats: png, jpg, gif. Max size: 1 MB.</p>
            <div class="profile__change-logo">
                <p class="profile__logo">AF</p>
                <input name="file" type="file" id="upload_logo" class="input input__file" multiple>
				<label for="upload_logo" class="input__logo-button">
                    Upload Image
				</label>
            </div>
        </div>
        <div class="profile__item password">
            <h3 class="profile__title">Personal info</h3>
            <p class="profile__info">Enter time on tasks and projects using a weekly timesheet view. While activated, project is a required field for the whole workspace.</p>
            <div class="profile__form">
                <p class="profile__name-p profile__p">
                    <label for="" class="profile__name-label profile__label">Email</label>
                    <input type="text" disabled v-model="store.currentUser.e" class="profile__name-input profile__input">
                </p>
                <p class="profile__mail-p profile__p">
                    <label for="" class="profile__mail-label profile__label">Name</label>
                    <input type="text" @blur="changeName($event)" v-model="store.currentUser.t" class="profile__mail-input profile__input">
                </p>
                <button class="profile__change-mail" v-on:click="showChangeEmail = !showChangeEmail">
                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M20 11C20 15.9706 15.9706 20 11 20V22C17.0751 22 22 17.0751 22 11H20ZM11 20C6.02944 20 2 15.9706 2 11H0C0 17.0751 4.92487 22 11 22V20ZM2 11C2 6.02944 6.02944 2 11 2V0C4.92487 0 0 4.92487 0 11H2ZM11 2C15.9706 2 20 6.02944 20 11H22C22 4.92487 17.0751 0 11 0V2Z" fill="#E7E7E8"/>
                        <path d="M10 15C10 15.5523 10.4477 16 11 16C11.5523 16 12 15.5523 12 15H10ZM12 7C12 6.44772 11.5523 6 11 6C10.4477 6 10 6.44772 10 7H12ZM15 12C15.5523 12 16 11.5523 16 11C16 10.4477 15.5523 10 15 10V12ZM7 10C6.44772 10 6 10.4477 6 11C6 11.5523 6.44772 12 7 12V10ZM12 15V11H10V15H12ZM12 11V7H10V11H12ZM11 12H15V10H11V12ZM11 10H7V12H11V10Z" fill="#E7E7E8"/>
                    </svg>
                </button>
            </div>
            <button class="profile__change-password" v-on:click="showChangePassword = !showChangePassword">Change password</button>
        </div>
        <div v-if="showGroupTimeEntries" class="profile__item group">
            <h3 class="profile__title">Group time entries</h3>
            <p class="profile__info">Entries for the same activity are grouped for easier overview. Disable if you wish to see entries in chronological order.</p>
            <div class="profile__group">
                <input class="profile__collapse-input" type="text">
                <v-selectize v-model="selected" :options="['Collapse if too many projects','Collapse all', 'Don`t collapse']"/>
            </div>
        </div>
        <div v-if="showTaskFilter" class="profile__item task">
            <h3 class="profile__title">Task filter</h3>
            <p class="profile__info">Quickly find the right task in project picker by using the task@project syntax.</p>
            <div class="profile__task">
                    <input class="task-filter" type="checkbox" name="" id="task-filter"><label for="task-filter" class="task-filter__label">Activate task filter</label>
            </div>
        </div>
        <div v-if="showEmailNotifications" class="profile__item notification">
            <h3 class="profile__title">Email notifications</h3>
            <p class="profile__info">Receive updates about your time and new features via email.</p>
            <div class="profile__notification">
                <p class="notification__item">
                    <input class="notification__input" type="checkbox" name="" id="trackify">
                    <label class="notification__label" for="trackify">Trackify can send me newsletters over e-mail</label>
                </p>
                <p class="notification__item">
                    <input class="notification__input" type="checkbox" name="" id="weekly">
                    <label class="notification__label" for="weekly"> Send weekly time entry updates</label>
                </p>
                <p class="notification__item">
                    <input class="notification__input" type="checkbox" name="" id="timers">
                    <label class="notification__label" for="timers">Email me about long-running (over 8 hours) timers</label>
                </p>
            </div>
        </div>
        <div v-if="showTimeSettings" class="profile__item time">
            <h3 class="profile__title">Time settings</h3>
            <p class="profile__info">Display time in clock format (with or without seconds).</p>
            <div class="profile__time">
                <p class="profile__label--time">Time zone</p>
                <p class="profile__p--time" v-on:click="showSelectTimeZone = !showSelectTimeZone">
                    <span>(UTC +03:00) Europe/Moscow</span>
                    <svg width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M3.63774 4.10521C3.83781 4.29826 4.16219 4.29826 4.36226 4.10521L7.22392 1.3439C7.54666 1.03248 7.31808 0.5 6.86166 0.5H1.13834C0.681918 0.5 0.453341 1.03248 0.776079 1.3439L3.63774 4.10521Z" fill="black"/>
                    </svg>
                </p>
                <SelectTimeZone v-if="showSelectTimeZone" @close="showSelectTimeZone = false"/>
            </div>
            <div class="profile__format">
                <div class="profile__date-format">
                    <p class="profile__label--time">Date format</p>
                    <v-selectize v-model="dateFormat" :options="['DD.MM.YYYY','MM.DD.YYYY', 'YYYY.DD.MM']"/>
                </div>
                <div class="profile__time-format">
                    <p class="profile__label--time">Time format</p>
                    <v-selectize v-model="timeFormat" :options="['24-hour','12-hour']"/>
                </div>
            </div>
            <div class="profile__week">
                <p class="profile__label--time">Week start</p>
                <v-selectize v-model="weekStart" :options="['Monday', 'Tuesday', 'Wednesday']"/>
            </div>
            <div class="profile__day">
                <p class="profile__label--time">Day start</p>
                <input class="profile__collapse-input" type="text" placeholder="9:00">
            </div>
        </div>
        <div v-if="showApi" class="profile__item api">
            <h3 class="profile__title">API</h3>
            <p class="profile__info">Connect Trackify to other apps using your personal API key</p>
            <div class="profile__api">
                <input class="profile__collapse-input" type="text" >
                <button class="profile__change-password"> Generate</button>
            </div>
        </div>
        <div v-if="showWebhooks" class="profile__item webhooks">
            <h3 class="profile__title">Webhooks</h3>
            <p class="profile__info">Create triggers so Trackify can let you know when something happens in your workspace.</p>
            <div class="profile__webhooks">
                <button class="profile__change-password"> Manage webhooks</button>
            </div>
        </div>
        <ChangePassword v-if="showChangePassword" @close="showChangePassword = false"/>
        <div class="bg" v-if="showChangePassword" @close="showChangePassword = false"></div>
        <ChangeEmail v-if="showChangeEmail" @close="showChangeEmail = false"/>
        <div class="bg" v-if="showChangeEmail" @close="showChangeEmail = false"></div>
    </div>
</template>

<script>
    import ChangePassword from '@/components/Modal/ChangePassword'
    import ChangeEmail from '@/components/Modal/ChangeEmail'
    import 'selectize/dist/css/selectize.default.css'
    import VSelectize from '@isneezy/vue-selectize'
    import SelectTimeZone from '@/components/Modal/SelectTimeZone'
    import { useProfileStore } from '@/store/ProfileStore'


    export default {
        data() {
            const profileStore = useProfileStore()
            return {
                store: profileStore,
                showChangePassword: false,
                showChangeEmail: false,
                selected: 'Collapse if too many projects',
                showSelectTimeZone: false,
                dateFormat: "DD.MM.YYYY",
                timeFormat: "24-hour",
                weekStart: "Monday",
                showLogo: false,
                showGroupTimeEntries: false,
                showTaskFilter: false,
                showEmailNotifications: false,
                showTimeSettings: false,
                showApi: false,
                showWebhooks: false
            }
        },
        components: {
            ChangePassword, ChangeEmail,  VSelectize, SelectTimeZone
        },
        methods:{
            changeName(event){
                this.store.update( { t: event.target.value } )
            }
        },
        created() {
            this.store.getUser()
        }
    }
</script>

<style scoped>
    h3,
    p{
        padding: 0;
        margin: 0;
    }

    input{
        outline: none;
    }

    button{
        border: none;
        background: none;
        cursor: pointer;
    }

    .profile__wrp{
        background-color: #fff;
        width: 100%;
        height: 100%;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.07);
        padding: 0 25px;
        margin-top: 20px;
        box-sizing: border-box;
        
    }

    .profile__title{
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        padding-bottom: 10px;
    }

    .profile__item{
        border-bottom: 2px solid #F2F3F6;
        padding: 20px 0;
    }

    .profile__info{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #BABCBF;
    }

    .profile__change-logo{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
    }

    .profile__logo{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: #E058B2;
        text-align: center;
        line-height: 100px;
        font-size: 38px;
        font-weight: 600;
        color: #fff;
    }

    .input__file {
        opacity: 0;
        visibility: hidden;
        position: absolute;
    }

    .input__logo-button{
        height: 42px;
        width: 167px;
        border: 2px solid #9CEDFF;
        border-radius: 30px;
        text-align: center;
        font-size: 16px;
        font-weight: 600;
        line-height: 42px;
        background: #9CEDFF1A;
        margin-left: 16px;
        cursor: pointer;
    }

    .profile__form{
        display: flex;
        flex-direction: row;
        margin: 20px 0;
        flex-wrap: wrap;
    }

    .profile__p{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }

    .profile__p:first-child{
        margin-right: 20px;
    }

    .profile__label{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        color: #606569;
    }

    .profile__input{
        width: 300px;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 30px;
        height: 42px;
        margin-top: 10px;
        outline: none;
        padding: 10px 15px;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
    }

    .profile__change-password{
        height: 42px;
        width: 174px;
        border: 2px solid #9CEDFF;
        border-radius: 30px;
        text-align: center;
        background: #9CEDFF1A;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
    }

    .profile__change-mail{
        width: 20px;
        height: 20px;
        align-self: center;
        padding-top: 11px;
        padding-left: 10px;
    }

    .profile__group{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 10px;
    }

    .profile__collapse-input{
        width: 120px;
        height: 42px;
        border: 2px solid #F2F3F6;
        box-sizing: border-box;
        border-radius: 30px;
        padding: 10px 15px;
        margin-right: 10px;
        font-family: Montserrat;
    }

    .profile__task{
        display: flex;
        flex-direction: column;
    }

    .profile__task input{
        display: none;
    }

    .profile__task label{
        position: relative;
        width: 150px;
        height: 20px;
        margin: 15px 0 0 0;
        padding-left: 50px;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        cursor: pointer;
    }

    .profile__task label:after{
        position: absolute;
        content: '';
        width: 36px;
        height: 20px;
        background-image: url('../../assets/img/off.png');
        top: 0;
        left: 0;
    }

    .task-filter:checked ~ .task-filter__label:after{
        background-image: url('../../assets/img/on.png');
    }

    .notification__item{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }

        .notification__input{
        display: none;
    }

    .notification__label{
        position: relative;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 17px;
        padding-left: 30px;
        cursor: pointer;
    }

    .notification__label:before{
        position: absolute;
        content: "";
        width: 14px;
        height: 14px;
        border: 2px solid #9CEDFF;
        border-radius: 4px;
        top: 0;
        left: 0;
    }

    .notification__input:checked ~ .notification__label:before,
    .notification__input:disabled ~ .notification__label:before{
        background-image: url('../../assets/img/check.svg');
        background-repeat: no-repeat;
        background-position: center;
    }

    .notification__input:disabled ~ .notification__label{
        color: #BABCBF;
    }

    .notification__label span{
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        padding-top: 3px;
    }

    .profile__webhooks{
        padding: 20px 0 10px 0;
    }


    .profile__label--time{
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        color: #BABCBF;
        margin-top: 15px;
    }

    .profile__p--time{
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        margin-top: 5px;
        cursor: pointer;
    }

    .profile__p--time svg{
        margin-left: 5px;
    }

    .profile__time{
        position: relative;
    }

    .profile__format{
        display: flex;
        flex-direction: row;
    }

    .profile__time-format,
    .profile__date-format,
    .profile__week{
        width: 140px;
        margin-right: 30px;
    }

    .profile__week .profile__label--time,
    .profile__day .profile__label--time{
        margin-top: 0;
    }

    .profile__day .profile__collapse-input{
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;
    }

    .profile__api .profile__collapse-input{
        width: 600px;
        margin-top: 10px;
    }

    .profile__api .profile__change-password{
        width: 107px;
    }

    @media(max-width: 1086px){
        .profile__api .profile__change-password{
            margin-top: 10px;
        }
    }

    @media(max-width: 980px){
        .profile__api .profile__collapse-input{
            width: 100%;
        }
    }

    @media(max-width:560px){
        .profile__wrp{
            padding: 0 15px;
        }
    }

    @media(max-width: 450px){
        .profile__change-logo{
            flex-direction: column
        }
        .profile__input{
            width: 100%;
        }

        .profile__group .profile__collapse-input{
            margin-bottom: 10px;
        }

        .profile__format{
            flex-wrap: wrap;
        }

        .input__logo-button{
            margin-top: 10px;
        }

        .profile__p:first-child{
            margin-right: 0;
        }
    }

</style>